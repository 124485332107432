// Libraries
import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom'

// Layouts
import layouts from 'layouts'

import { logPageView } from '../utils/ga'

const TrackPageView = () => {
    const location = useLocation()

    useEffect(() => {
        logPageView()
    }, [location])

    return null
}

const Router = () => {
    return (
        <BrowserRouter>
            <TrackPageView />

            <Switch>
                {layouts.map((route, routeIndex) => {
                    return route.component ? <Route key={routeIndex} path={route.path} exact={route.exact} component={route.component} /> : null
                })}
            </Switch>
        </BrowserRouter>
    )
}

export default Router
