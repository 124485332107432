import ReactGA from 'react-ga4'

const GA_MEASUREMENT_ID = 'G-MQ7S6CTVFM'

export const initGA = () => {
    ReactGA.initialize(GA_MEASUREMENT_ID)
}

export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
}

export const logEvent = (category = '', action = '', label = '') => {
    if (category && action) {
        ReactGA.event({
            category,
            action,
            label,
        })
    }
}
